<template>
  <div class="flex flex-col md:items-center p-5 sm:py-5">
    <div class="bg-blue-950 rounded-3xl p-3 md:p-6 md:w-11/12 flex">
      <!-- Formulario de usuario conocido/anonimo -->
      <div class="w-11/12 md:w-1/2 m-3">
        <form ref="form" class="flex flex-col" @submit.prevent="submitForm">
          <p class="text-lg text-yellow-300 font-semibold mb-4">Escoja si quiere que se visualicen sus datos personales</p>

          <div class="flex items-center mb-4">
            <input type="radio" id="conocido" name="tipo" value="conocido" v-model="tipoUsuario" class="mr-2" />
            <label for="conocido" class="text-xl text-yellow-300">Conocido</label>
          </div>

          <div class="flex items-center mb-6">
            <input type="radio" id="anonimo" name="tipo" value="anonimo" v-model="tipoUsuario" class="mr-2" />
            <label for="anonimo" class="text-xl text-yellow-300">Anónimo</label>
          </div>

          <!-- Formulario con campos que se bloquean si es Anónimo -->
          <div class="flex flex-col mb-6">
            <label for="nombre" class="text-xl text-yellow-300 font-semibold">Nombre</label>
            <input type="text" name="user_name" v-model="nombre" :disabled="isAnonymous" class="border border-gray-300 p-2 rounded mt-2" />

            <label for="telefono" class="text-xl text-yellow-300 font-semibold mt-4">Teléfono/Móvil</label>
            <input type="text" v-model="telefono" :disabled="isAnonymous" class="border border-gray-300 p-2 rounded mt-2" />

            <label for="lugar" class="text-xl text-yellow-300 font-semibold mt-4">Lugar</label>
            <input type="text" v-model="lugar" :disabled="isAnonymous" class="border border-gray-300 p-2 rounded mt-2" />

            <label for="email" class="text-xl text-yellow-300 font-semibold mt-4">Email</label>
            <input type="email" name="user_email" v-model="email" class="border border-gray-300 p-2 rounded mt-2" />
          </div>

          <!-- Separación con barra y Sección A -->
          <hr class="border-t-2 border-gray-400 my-6" />
          <h2 class="text-2xl font-semibold text-yellow-300 mb-4 text-center">SECCIÓN A</h2>

          <div class="flex flex-col mb-6">
            <div class="flex gap-6">
              <div class="flex flex-col justify-center">
                <label for="fecha" class="text-xl text-yellow-300 font-semibold">Fecha de lo ocurrido</label>
                <input type="date" v-model="fechaOcurrido" name="date" class="border border-gray-300 p-2 rounded mt-2" />
              </div>
              <div class="flex flex-col justify-center">
                <label for="lugarOcurrido" class="text-xl text-yellow-300 font-semibold">Lugar</label>
                <input type="text" v-model="lugarOcurrido" name="place" class="border border-gray-300 p-2 w-2/3 rounded mt-2" />
              </div>
            </div>

            <label for="descripcion" class="text-xl text-yellow-300 font-semibold mt-4">Descripción</label>
            <textarea v-model="descripcion" name="message" class="border border-gray-300 p-2 rounded mt-2" rows="4"></textarea>

            <label for="archivo" class="text-xl text-yellow-300 font-semibold mt-4">Seleccionar Archivo</label>
            <input type="file" @change="handleFileUpload" class="border border-gray-300 p-2 rounded mt-2" />

            <label for="accionCorrectiva" class="text-xl text-yellow-300 font-semibold mt-4">Sugerir alguna acción correctiva</label>
            <textarea name="sugerencia" v-model="accionCorrectiva" class="border border-gray-300 p-2 rounded mt-2" rows="3"></textarea>

            <!-- Menús desplegables para opiniones -->
            <label for="opinion" class="text-xl text-yellow-300 font-semibold mt-4">En su opinión cual es la probabilidad de que suceda lo descrito?(1 improbable 5 frecuente)</label>
            <select v-model="opinion" name="opinion1" class="border border-gray-300 p-2 rounded mt-2">
              <option value="" disabled>Seleccione una opción</option>
              <option v-for="n in 5" :key="n" :value="n">{{ n }}</option>
            </select>

            <label for="opinion2" class="text-xl text-yellow-300 font-semibold mt-4">En su opinión usted cree que es una consecuencia grave? (1 improbable 5 frecuente)</label>
            <select v-model="opinion2" name="opinion2" class="border border-gray-300 p-2 rounded mt-2">
              <option value="" disabled>Seleccione una opción</option>
              <option v-for="n in 5" :key="n" :value="n">{{ n }}</option>
            </select>
          </div>

          <button type="submit" class="bg-yellow-300 hover:bg-yellow-500 font-bold py-2 px-4 rounded mt-4">
            ENVIAR
          </button>
        </form>
      </div>
      <div class="image-slider hidden md:block">
        <img :src="imagenes[currentImageIndex]" alt="Imagen rotativa" class="slider-image w-2/5 sm:h-2/3 lg:h-5/6 rounded-3xl fixed">
      </div>
    </div>

    <div>
      <button @click="logout" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-6 rounded">
        Cerrar sesión
      </button>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: 'HomePage',
  data() {
    return {
      tipoUsuario: 'conocido',
      nombre: '',
      telefono: '',
      lugar: '',
      email: '',
      fechaOcurrido: '',
      lugarOcurrido: '',
      descripcion: '',
      archivo: null,
      accionCorrectiva: '',
      opinion: '',
      opinion2: '',
      imagenes: [
        require('@/assets/tam1.jpg'),
        require('@/assets/tam2.jpg'),
        require('@/assets/tam3.jpg'),
        require('@/assets/tam4.jpg'),
      ],
      currentImageIndex: 0, // Índice actual de la imagen que se muestra
    }
  },
  computed: {
    isAnonymous() {
      return this.tipoUsuario === 'anonimo';
    },
  },
  mounted() {
    this.startImageRotation();
  },
  methods: {
    submitForm() {
      emailjs
        .sendForm('service_qgot9x7', 'template_ngahg8l', this.$refs.form, 'VtINNU9HrqSYexYRU')
        .then(
          () => {
            console.log('Formulario enviado con éxito');
          },
          (error) => {
            console.log('Error al enviar el formulario:', error.text);
          }
        );
    },
    handleFileUpload(event) {
      this.archivo = event.target.files[0];
    },
    startImageRotation() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.imagenes.length;
      }, 3000);
    },
    logout() {
      localStorage.removeItem('loggedIn');
      this.$router.push('/');
    },
  },
}
</script>