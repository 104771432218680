<template>
  <div class="flex items-center justify-center">
    <!-- carrusel de imagenes -->
    <div class="w-full max-w-3xl p-5">
      <form @submit.prevent="login" class="md:flex bg-gradient-to-r from-blue-500 to-purple-500 shadow-2xl rounded-lg p-3 md:py-8 md:pl-8 gap-2 md:gap-5 lg:gap-8">
        <div class="image-slider w-full py-5 md:py-0 md:pr-0 md:w-3/5">
          <img :src="imagenes[currentImageIndex]" alt="" class="slider-image">
        </div>
        <div class="flex flex-col justify-center w-full pb-5 lg:pr-0 md:pr-0 md:w-1/3">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
              Username
            </label>
            <input
              v-model="username"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Enter Username"
              required
            />
          </div>
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
              Password
            </label>
            <input
              v-model="password"
              class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="******************"
              required
            />
          </div>
          <div class="flex items-center justify-center">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Ingresar
            </button>
          </div>
          <p v-if="error" class="text-red-500 text-xs italic mt-2">{{ error }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm', // Cambiado a multi-palabra
  data() {
    return {
      username: '',
      password: '',
      error: null,
      correctUsername: 'admin', // Credenciales fijas
      correctPassword: '1234',
      imagenes: [
        require('@/assets/tam1.jpg'),
        require('@/assets/tam2.jpg'),
        require('@/assets/tam3.jpg'),
        require('@/assets/tam4.jpg'),
      ],
      currentImageIndex: 0,
    }
  },
  mounted() {
    this.startImageRotation();
  },
  methods: {
    login() {
      if (this.username === this.correctUsername && this.password === this.correctPassword) {
        localStorage.setItem('loggedIn', 'true')
        this.$router.push('/home')
      } else {
        this.error = 'Invalid username or password'
      }
    },
    startImageRotation() {
      setInterval(()=> {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.imagenes.length;
      }, 3000);
    },
  },
}
</script>

<style scoped>
/* estilos adicionales opcional */
</style>
