<template>
  <div class="text-center pt-2 bg-yellow-300">
    <h1 class="lg:text-4xl md:text-3xl text-2xl font-bold text-blue-600">SISTEMA DE GESTIÓN DE LA SEGURIDAD OPERACIONAL</h1>
  </div>
  <!-- <HelloWorld msg="TAM"/> -->
   <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>