import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/LoginForm.vue'
import Home from '../components/HomePage.vue'

// Configuración de rutas
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
]

// Crear la instancia del router
const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Verificar si la ruta requiere autenticación
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('loggedIn')

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router